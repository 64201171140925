// Variable overrides first
$primary: #2FAABB;
$secondary: #FFB225;
$light:#F0F2F5;
$textlight:#365b71;
$textdark:#232233;
$darkbg: #0B9BAF;





// // Sovrascrivi le dimensioni del container
// $container-max-widths: (sm: 540px,
//     md: 720px,
//     lg: 960px,
//     xl: 1140px,
//     xxl: 1700px // Cambia questa dimensione
// );


@use "sass:math";
@import "./node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600;700&display=swap');

// Importa il CSS di Owl Carousel nel tuo file SCSS

//UTILITA di CONVERSIONE PX TO EM
@function px-to-rem($px, $base-font-size: 16px) {
    @return math.div($px, $base-font-size) * 1rem;
}

html {
    scroll-behavior: smooth;
}


body {
    font-family: 'Josefin Sans', sans-serif;
}

.container-fluid-max {
    // width: 100%;
    max-width: 1700px;
    // margin-right: auto;
    // margin-left: auto;
  }


.darkbg{
    background: $darkbg;
}

.header-background {
    height: 70vh;
    background: linear-gradient(125deg, rgba(25, 140, 224, 0.69) -0.35%, #FFF 53.54%);
        min-height: 705px;
    }
.custom-list li {
    list-style-type: none;
    /* Rimuove il pallino predefinito */
    background-image: url('./risorse/img/check-giallo.svg');
    /* Percorso al tuo SVG */
    background-repeat: no-repeat;
    background-size: px-to-rem(34px);
    /* Regola la dimensione dell'immagine SVG */
    background-position: 0 0.1em;
    /* Regola la posizione dell'immagine SVG */
    padding-left: 2.6em;
    /* Spazio per l'immagine SVG */
    color: $textlight;
    font-family: Josefin Sans;
    font-size: px-to-rem(20px);
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 26px */
    min-height: px-to-rem(40px);
    text-transform: uppercase;
    margin-bottom: px-to-rem(20px);
}


.text-light-secondary{
    color: $textlight;
}

h1 {
    color: $textlight;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 31.2px */
}

h2 {
    color: $textdark;
    font-weight: 700;
    line-height: 130%;
}

h3 {
    color: $textdark;
}

p {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

// Aggiungi stili personalizzati
//24px / 16px = 1.5rem
h1 {
    font-size: px-to-rem(24px);
}

.navbar-brand img {
    min-height: 72px;
    height: 105px;
}

@media screen and (max-width:800px){
  .navbar-brand img {
    min-height: 72px;
    height: 100%;
}
    
}

.btn {
    font-weight: 800;
    font-size: px-to-rem(21px);
    ;
}


.icon-calendar {
    background-image: url(./risorse/img/calendario.svg);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 45px;
    padding-left: 53px;
}

#card-form-registrazione {

    h3 {
        color: #fff;
        text-transform: uppercase;
        font-family: Josefin Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 89.596%;
        /* border: 1px solid red; */
        min-height: 49px;
        max-width: 390px;

    }

}

.header-background {

    position: relative;

}



//ANIMAZIONI
#stage {

    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
    overflow: hidden;
    .casa-centrale {
        position: absolute;
        bottom: 0px;
        display: flex;
        width: 100%;
        justify-content: center;
        z-index: 1;
    }


    #nuvoletta {
        img {
            width: 100%;
        }

        width: 30vw;
        position: absolute;
        bottom: 50%;
        left: 10%;
        max-width: 680px;
    }


    .casa-outer {
        position: relative;
        transform: translateX(-14%);

        .casa {
            z-index: 1;
            position: relative;
            img{
                height: 43vh;
                min-height: 433px;
            }
        }

        .pala-eolica {
            position: absolute;
            bottom: 40%;
            right: 26%;
            z-index: 0;

            .elica {
                position: absolute;
                left: -101px;
                top: -137px;
            }
        }
    }

    #bonus {
        justify-content: center;
        display: flex;
    }

    .linea-obliqua {
        img {
            width: 100%;
        }

        position: absolute;
        width: 100%;
        bottom: 0px;
        left: 0px;
        z-index: 0
    }
}

.lead{
    font-size: 30px;
    font-weight: 400;
}


/* Stile per la freccia di navigazione precedente */
.owl-carousel .owl-nav .owl-prev {
    position: absolute;
    left: -40px; /* Distanza dal lato sinistro del carousel */
    top: 50%;
    transform: translateY(-40%);
    background: url('./risorse/img/arrow-b.svg') no-repeat center center!important;
    width: 30px; /* Larghezza della freccia */
    height: 53px; /* Altezza della freccia */
    border: none; /* Rimuovi qualsiasi bordo */
    outline: none;
    cursor: pointer;
}

/* Stile per la freccia di navigazione successiva */
.owl-carousel .owl-nav .owl-next {
    position: absolute;
    right: -40px; /* Distanza dal lato destro del carousel */
    top: 50%;
    transform: translateY(-40%);
    background: url('./risorse/img/arrow-n.svg') no-repeat center center!important;
    width: 30px; /* Larghezza della freccia */
    height: 53px; /* Altezza della freccia */
    border: none; /* Rimuovi qualsiasi bordo */
    outline: none;
    cursor: pointer;
}
.owl-nav span{
    display: none;
}
/* Opzionalmente, rimuovere il testo di default con accessibilità in mente */
.owl-carousel .owl-nav .owl-prev:after,
.owl-carousel .owl-nav .owl-next:after {
    content: "";
}



.custom-list-line{
    
    li{
        margin-bottom: 0px;
        margin-bottom: 10px!important;      
        display: flex!important;
        align-items: center!important;
    }
    li.line-break{
        margin: 0px !important;
        padding: 0px !important;
        background: none !important;
        height: 1px !important;
        overflow: hidden !important;
        min-height: 1px;
        background: #CFCFCF!important;
        margin-bottom: 10px!important;
    }
}

.dark-arrow  .owl-nav .owl-prev {
    position: absolute;
    left: -40px; /* Distanza dal lato sinistro del carousel */
    top: 50%;
    transform: translateY(-40%);
    background: url('./risorse/img/arrow-dark-b.svg') no-repeat center center!important;
    width: 30px; /* Larghezza della freccia */
    height: 53px; /* Altezza della freccia */
    border: none; /* Rimuovi qualsiasi bordo */
    outline: none;
    cursor: pointer;
}
.dark-arrow .owl-nav .owl-next {
    position: absolute;
    right: -40px; /* Distanza dal lato destro del carousel */
    top: 50%;
    transform: translateY(-40%);
    background: url('./risorse/img/arrow-dark-n.svg') no-repeat center center!important;
    width: 30px; /* Larghezza della freccia */
    height: 53px; /* Altezza della freccia */
    border: none; /* Rimuovi qualsiasi bordo */
    outline: none;
    cursor: pointer;

}

.base-background-image{
    background: url(./risorse/img/bambino-energia.jpg);
    background-size: cover;
    @media screen and (max-width:1596px){
        background-image: 
        linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5)), /* Sfondo bianco con 50% di opacità */
        url(./risorse/img/bambino-energia.jpg); /* La tua immagine di sfondo */
    }
    transition: all 0.5s ease;
}


footer{
    background: #13232C;
}

.max-width-custom{
    max-width: 1300px;
    margin: 0 auto;
}
.owl-dots{
    height: 40px;
    display: flex;
    justify-content: center;
    column-gap: 10px;
    align-items: center;
    .owl-dot{
        height: 16px;
        width:16px;
        display: block;
        background: #BBBBBB!important;
        border-radius: 30px;
        transition: all 0.5s ease;
    }
    .active{
        height: 26px;
        width:26px;
        background:#FFB225!important;
    }
}





.name-image-title{
    display: flex;
    // img{
    //     max-width: 50px;
    // }
}
.stelline{
    img{
        max-width: 150px;
    }
    padding-bottom: 20px;
    font-size:18px;
}
.profile-image{
    max-width: 66px;
}


@media screen and (max-width:767px){
    #bonus{
        display: none!important;
    }
    #stage .casa-outer{
        transform: translate(0);
        padding:20px;
    }
    .header-background{
     height: 0px;   
    }
    #stage .casa-outer .casa img {
    height: auto!important;
    min-height: auto!important;
    }
    .header-background {
        min-height: 136vw;
    }
    #stage .casa-outer .pala-eolica{
    transform: scale(.5) translateX(10px) translateY(100px)!important;
    }
    #stage #nuvoletta{
        zoom: 3;
        left: -50%;
    }
}

@media screen and (max-width:575px){
    #barra-logo{
        border-radius: 0px!important;
    }

}

@media screen and (max-width:400px){
    #barra-logo{
        border-radius: 0px!important;
    }
    .header-background{
        height:542.625px;
    }
}

@media screen and (max-width:700px){
    #monete{
        max-width: 60px;
    }
}
#banner-circle-top{
display: none;
}
@media screen and (max-width:1199px){
    #banner-circle-top{
     width: 180px;
    }

    #banner-circle-top{
        position: absolute;
        z-index: 1000;
        right: 10px;
        top: -20px;
        display: block;
    }
    #bonus{
        display: none!important;
    }
}
@media screen and (max-width:700px){
  

    #banner-circle-top{
        position: absolute;
        z-index: 1000;
        right: 10px;
        top: -20px;
        width: 100px;
        display: block;
    }
   
}




@media screen and (max-width:767px){
    .header-background {
        min-height: 97vw;
    }
        
    }
    @media screen and (max-width:704px){
    .header-background {
        min-height: 120vw;
    }
        
    }
    @media screen and (max-width:470px){
    .header-background {
        min-height: 150vw;
    }
        
    }



    #carosello-recensioni{
        .card{
            min-height:300px;
        }
    }


    .profile-image{
        width: 55px!important;
        height: 50px!important
    }

    .senza-impegno{
        color: #FFF;
            font-family: Josefin Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            position: absolute;
            right: 0px;
            top: 15px;
            width: 137px;
            height: 19px;
            transform: rotate(-23.206deg);
            text-align: center;
        }

        .fade-show {
            opacity: 0;
            /* Altri stili di base se necessario */
          }

          :root {
            --animate-delay: 2s;
          }